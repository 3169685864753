import React from "react";
import Apt from "../assets/apt-stock.png";

import VideoGenerator from "./VideoGenerator.tsx";

export default function Landing() {
  return (
    <>
      <div className="zzmb flex">
        <div className="hidden lg:block w-1/3 flex justify-start items-center mr-8">
          <img
            src={Apt}
            alt="Apartments.com Logo"
            className=" "
            style={{ width: "100%" }}
          />
        </div>

        <div>
          <VideoGenerator />
        </div>
      </div>
    </>
  );
}
