import { Label } from "./types.tsx";

export function GeneratePropertyMap(labels: Array<Labels>) {
  const data = {};
  labels.forEach((p) => (data[p.label] = p.name));
  return data;
}

// export const data   = {

//     1475823 : "Cosmopolitan Condos" ,
//     776960 : "Fountain View Patio Homes" ,
//     621772 : "2016 Main" ,
