import * as React from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { DownloadVideo } from "./DownloadVideo.tsx";
// import VideoEmbed from "./VideoPlayerEmbed.tsx";
import DownloadIcon from "@mui/icons-material/Download";
import { MuiTelInput } from "mui-tel-input";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AccountBox from "@mui/icons-material/AccountBox";
import WorkIcon from "@mui/icons-material/Work";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ListItemButton from "@mui/material/ListItemButton";
import Paper from "@mui/material/Paper";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ReactGA from "react-ga4";

type videoObject = {
  customerName: string;
  repName: string;
  phone: string;
  address: string;
};

export default function Form({
  hiddenClass,
  id,
  func,
  propertyName,
}: {
  hiddenClass: boolean;
  id: string;
  func: any;
  propertyName: string;
}) {
  const [formFields, setFields] = React.useState({
    // Initial Values for Form
    customerName: "",
    repName: "",
    phone: "",
    address: "",
  });
  const [loading, setLoading] = React.useState(false); // State for Loader
  const [videoStatus, setStatus] = React.useState("notloaded"); // State for Video Status
  const [apiResult, setResult] = React.useState(""); // State for API Result
  const [open, setOpen] = React.useState(false); // State for Form Validation

  const phoneNumberWithDashes = (phoneNumber: string): string =>
    phoneNumber.slice(3).replace(/\s/g, "-"); // Converting Phone Number from 123 456 7890 format to 123-456-7890 format

  const resetFields = () =>
    setFields({
      ...formFields,
      customerName: "",
      repName: "",
      phone: "",
      address: "",
    });

  const resetAll = () => {
    // Function to reset State for Form and Autocomplete
    setFields({ customerName: "", repName: "", phone: "", address: "" });
    setLoading(false);
    setStatus("notloaded");
    setResult("");
    setOpen(false);
    func(false);
  };

  // Async Video API Result Function
  async function getVideo({
    customerName,
    repName,
    phone,
    address,
  }: videoObject) {
    if (
      customerName === "" ||
      repName === "" ||
      phone === "" ||
      address === "" ||
      address.indexOf("@") === -1
    ) {
      // TODO Low Priority : Add Popup that will let users know to enter all fields before proceeding
      setOpen(true);
      return;
    }

    let api = `https://www.adgreetz.com/v1/video/md5/3719?name=${customerName}&propertyID=${id}&rep_name=${repName}&rep_email=${address}&rep_phone=${phoneNumberWithDashes(
      phone
    )}`;
    let embedApi = `https://www.adgreetz.com/embed/${id}?name=${customerName}&propertyID=${id}&rep_name=${repName}&rep_email=${address}&rep_phone=${phoneNumberWithDashes(
      phone
    )}`;
    setLoading(true);

    // Google Analytics
    ReactGA.event("Create Video", {
      customerName,
      repName,
      phone,
      address,
    });

    let response = await axios.get(api);
    let data = response.data;
    //console.log("DATA IS", data); Uncomment to view API Response
    setLoading(false);
    setStatus("loaded");
    setResult(data);
    //console.log(embedApi);
    // setResult(api);
    //console.log("DATA", data);
  }

  if (loading === true) {
    // Show Spinner if Video is Loading
    return (
      <div className="flex justify-center p-8 ">
        <CircularProgress color="success" />
      </div>
    );
  } else {
    if (videoStatus === "loaded") {
      // Render Video once Video is Loaded
      return (
        <div className="zzmb">
          <Grid container sx={{ width: "100%", mb: 2 }} gap={0}>
            <Grid item xs={12} sm={10} lg={8}>
              <Box
                sx={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  overflow: "hidden",
                }}
              >
                <Box
                  component="video"
                  sx={{
                    overflow: "hidden",
                    border: 0,
                    alignSelf: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  controls
                >
                  <source src={apiResult.stream} type="video/mp4" />
                  {/* <VideoEmbed src={apiResult} /> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={10} lg={4}>
              <Paper sx={{ ml: { sx: 0, lg: 2 } }} elevation={3}>
                <span className="text-2xl font-roboto flex justify-center font-raleway text-emerald-600 mt-2">
                  Personalization
                </span>
                <List
                  //orientation="horizontal"
                  sx={{
                    // width: "100%",
                    // maxWidth: "300px",
                    bgcolor: "background.paper",
                    flexDirection: { xs: "row", lg: "column" },
                    display: "flex",
                    // marginRight: "2rem",
                  }}
                >
                  <ListItemButton>
                    <ListItem
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", lg: "row" },
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AccountBox />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Customer"
                        secondary={formFields.customerName}
                      />
                    </ListItem>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItem
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", lg: "row" },
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <WorkIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Representative"
                        secondary={formFields.repName}
                      />
                    </ListItem>
                  </ListItemButton>

                  <ListItemButton>
                    <ListItem
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", lg: "row" },
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <SettingsPhoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Phone Number"
                        secondary={phoneNumberWithDashes(formFields.phone)}
                      />
                    </ListItem>
                  </ListItemButton>

                  <ListItemButton>
                    <ListItem
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", lg: "row" },
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <MailOutlineIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Email"
                        secondary={formFields.address}
                      />
                    </ListItem>
                  </ListItemButton>
                </List>
              </Paper>
            </Grid>
          </Grid>

          <div id="download-copy-component" className="mt-8">
            <div className="font-roboto text-xl mb-2">Video Link</div>{" "}
            <div className=" w-124 flex mr-4">
              <div className="overflow-x-hidden border-2 border-emerald-600 p-2">
                {`https://streamer.adgreetz.com/${apiResult.md5sum}.mp4`}
              </div>
              <Tooltip title="Click to Copy Video Link to Clipboard">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://streamer.adgreetz.com/${apiResult.md5sum}.mp4`
                    );
                  }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Download Video">
                <Button
                  variant="contained"
                  color="success"
                  onClick={async () => {
                    // download video
                    await DownloadVideo(apiResult.stream, propertyName);
                  }}
                >
                  <DownloadIcon />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="flex gap-2 mt-8 mb-4 ">
            <Button
              // Component to Reset All State and Create More Videos
              color="success"
              onClick={() => {
                resetAll();
              }}
              variant="contained"
            >
              Create More Videos
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            hiddenClass === true
              ? " transition-all duration-200 opacity-80"
              : "transition-all duration-200 opacity-20"
          }
        >
          <div id="form-based-on-property-id">
            <div id="customer" className="mb-4">
              <div className="font-bold">Customer</div>
              <div className="m-2">
                <div className="mb-1">First Name</div>
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(event): any =>
                    setFields({
                      ...formFields,
                      customerName: event.target.value,
                    })
                  }
                  value={formFields.customerName}
                  // defaultValue=""
                />
              </div>
            </div>

            <div id="rep">
              <div className="font-bold">Representative</div>
              <Grid container spacing={2} sx={{ pr: 2 }}>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1">Name</Typography>
                  <TextField
                    required
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) =>
                      setFields({
                        ...formFields,
                        repName: event.target.value,
                      })
                    }
                    // defaultValue=""
                    value={formFields.repName}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1">Phone Number</Typography>
                  <MuiTelInput
                    fullWidth
                    inputProps={{ maxLength: 12 }}
                    error={
                      formFields.phone.length > 0 &&
                      formFields.phone.length !== 15
                    }
                    forceCallingCode
                    defaultCountry="US"
                    value={formFields.phone}
                    onChange={(event) => {
                      setFields({
                        ...formFields,
                        phone: event,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography variant="body1">Email Address</Typography>
                  <TextField
                    required
                    fullWidth
                    error={!validateEmail(formFields.address)}
                    type="email"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) =>
                      setFields({
                        ...formFields,
                        address: event.target.value,
                      })
                    }
                    value={formFields.address}
                    // defaultValue=""
                  />
                </Grid>
              </Grid>

              <div
                className={open ? "opacity-90 text-2xl m-8" : "opacity-60 m-8"}
              >
                *All Fields Required
              </div>
              <div className="flex gap-8 p-4">
                <Button
                  sx={{ pl: 2, pr: 2 }}
                  color="error"
                  onClick={() => {
                    func(false);
                    resetFields();
                  }}
                  variant="contained"
                  startIcon={<DeleteIcon />}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ pl: 2, pr: 2 }}
                  onClick={() => {
                    getVideo(formFields);
                  }}
                  variant="contained"
                  endIcon={<SendIcon />}
                >
                  Create Video
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const validateEmail = (email: string) => {
  if (String(email).length > 63) {
    return false;
  }

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
