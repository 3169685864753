import ReactGA from "react-ga4";
import Landing from "./components/Landing.tsx";

ReactGA.initialize("G-7GX8ES1E9H");

function App() {
  return (
    <>
      <div className="flex justify-center bg-gradient-to-r from-blue-700 via-emerald-500 to-cyan-400 text-transparent bg-clip-text m-4 text-3xl font-raleway ">
        <h1>Apartments.com Video Generator</h1>
      </div>
      <div className="ml-8 mt-8 mr-1">
        <Landing />
      </div>
    </>
  );
}

export default App;
