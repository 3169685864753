import axios from "axios";

// Async function will Download the Video Locally to the Client

export async function DownloadVideo(url: string, fileName: string) {
  const downloadVideo = (url: string) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fileName + ".mp4");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  await downloadVideo(url);
}
