import * as React from "react";
// import { data } from "../data/data.ts"; // 90k objects
// import { properties } from "../data/labels.ts"; // 90k objects

import Form from "./Form.tsx";
import TextField from "@mui/material/TextField";
//import { FixedSizeList as List } from "react-window";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";
import { GeneratePropertyMap } from "../utils/genPropertyMap.tsx";

export default function VideoGenerator() {
  const [formVisibility, setVisibility] = React.useState(false);
  const [id, setId] = React.useState("");
  const [properties, setProperties] = React.useState([
    { label: "Loading Data...", name: "Loading Data.." },
  ]);
  const [data, setData] = React.useState({});

  const updateClass = (bool: boolean) => {
    setVisibility(bool);
    setId("");
  };

  // const Option = ({ index, style }) => (
  //   <div key={index} style={style}>
  //     {properties[index].label}
  //   </div>
  // );

  // --------> LAZY LOADING <-------------

  React.useEffect(() => {
    fetch("/data/labels.json")
      .then((resp) => resp.json())
      .then((labels) => {
        //console.log("useEffect Labels length : ", labels.length);
        setProperties(labels);
        const obj = GeneratePropertyMap(labels);
        setData(obj);
      });
  }, []);

  // --------> LAZY LOADING Logic End <-------------

  return (
    <>
      <div className=" mb-8 flex flex-col justify-center ">
        <span className="text-xl">Create a Custom Video for</span>
      </div>
      {!formVisibility && (
        <div className=" flex m-4 gap-4">
          <Autocomplete
            value={id}
            id="virtualize-demo"
            sx={{ width: 300 }}
            disableListWrap
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={properties}
            renderInput={(params) => (
              <TextField {...params} label="Property ID" />
            )}
            renderOption={(props, option, state) =>
              [props, option, state.index] as React.ReactNode
            }
            // TODO: Post React 18 update - validate this conversion, look like a hidden bug
            renderGroup={(params) => params as unknown as React.ReactNode}
            onChange={(event, value) => {
              if (value.label === "Loading Data...") return;
              if (value.label) {
                updateClass(true);
                setId(value.label);
                console.log(id);
              }
            }}
          />
        </div>
      )}

      {formVisibility && (
        <>
          <div className="text-3xl text-green-600  font-raleway mt-2">
            {data[id]}
          </div>
          <div className="mt-2 ">
            Property ID - <span className="italic">{id}</span>
          </div>
        </>
      )}
      <Form
        hiddenClass={formVisibility && id !== ""}
        id={id}
        func={updateClass}
        propertyName={data[id]}
      />
    </>
  );
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].label}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// function random(length: number) {
//   const characters =
//     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//   let result = "";

//   for (let i = 0; i < length; i += 1) {
//     result += characters.charAt(Math.floor(Math.random() * characters.length));
//   }

//   return result;
// }

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});
